import PropTypes from 'prop-types'

import './direccionCard.scss'
import { useTranslation } from 'react-i18next'
import Spinner from '../shared/components/Spinner'
import { useSelector } from 'react-redux'
import EditBtn from './EditBtn'

const DireccionCard = ({ direccion, isSelected, setDirEnvio, setIsOpenForm, isDirEnvio = true, isOutOfRange, isLoadingSelectDirEnvio, handleEditSchedule, handleEditDirFact }) => {
  const { t } = useTranslation()
  const checkoutInfo = useSelector(state => {
    return state.checkout.entity
  });
  const isFromEcommerce = checkoutInfo?.presupuesto?.ecommerce;
  return (
      <div
          className={`direccion-card ${isSelected ? 'selected' : ''} ${
              isOutOfRange && isDirEnvio ? 'out-of-range' : ''
          }`}
      >
          {isDirEnvio && (
              <input
                  onChange={() => {
                      if (!isOutOfRange) {
                          !isLoadingSelectDirEnvio && setDirEnvio(direccion);
                          setIsOpenForm(false);
                      }
                  }}
                  type="radio"
                  name="direccion"
                  checked={isSelected}
              />
          )}
          <div
              className={`direccion-card__text-container ${
                  isDirEnvio ? '' : 'direccion-card__no-cursor-pointer'
              }`}
              onClick={() => {
                  if (isDirEnvio && !isOutOfRange) {
                      setDirEnvio(direccion);
                      setIsOpenForm(false);
                  }
              }}
          >
              <h4>{direccion.nombre}</h4>
              {!isDirEnvio && <h5>{direccion?.cif}</h5>}
              {!isDirEnvio && <h5>{direccion?.email}</h5>}
              {isDirEnvio ? (
                  <p>
                      {direccion.direccion}, {direccion.codigo_postal}{' '}
                      {direccion.poblacion}, {direccion.area},{' '}
                      {direccion.pais.nombre}
                  </p>
              ) : (
                  <p>
                      {direccion.direccion_facturacion},{' '}
                      {direccion?.codigo_postal_facturacion}{' '}
                      {direccion?.poblacion_facturacion},{' '}
                      {direccion?.area_facturacion},{' '}
                      {direccion.pais_facturacion?.nombre}
                  </p>
              )}
              {isDirEnvio && (
                  <>
                      <p>
                          {t('carrito.direccion.tlf')} {direccion.telefono}
                      </p>
                  </>
              )}
              {!isDirEnvio && direccion?.pais_facturacion_id !== 1 && (
                  <p className="direccion-card__cif-ue">
                      <input
                          type="checkbox"
                          disabled
                          checked={
                              checkoutInfo.cliente.cif_ue === 1 ||
                              checkoutInfo.cliente.cif_ue === true
                          }
                      />
                      <span>{t('carrito.envio.form.cif_ue')}</span>
                  </p>
              )}
              {!isDirEnvio &&
                  isFromEcommerce &&
                  checkoutInfo?.cliente?.b2b === 1 && (
                      <p className="direccion-card__cif-ue">
                          <input
                              type="checkbox"
                              disabled
                              checked={
                                  checkoutInfo.cliente.b2b === 1 ||
                                  checkoutInfo.cliente.b2b === true
                              }
                          />
                          <span>{t('carrito.envio.form.b2b')}</span>
                      </p>
                  )}
              {isDirEnvio && (
                  <p>
                      <span>{t('carrito.direccion.horario')}: </span>
                      {direccion?.horario_entrega_dia &&
                      direccion?.horario_entrega_tarde
                          ? t('carrito.envio.form.schedule.both')
                          : direccion?.horario_entrega_dia
                            ? t('carrito.envio.form.schedule.morning')
                            : direccion?.horario_entrega_tarde
                                ? t('carrito.envio.form.schedule.afternoon')
                                : '-'}
                  </p>
              )}
              {isDirEnvio && isOutOfRange && (
                  <p className="direccion-card__text-out-of-range">
                      ({t('errors.fuera-rango')})
                  </p>
              )}
          </div>
          {isDirEnvio && !isOutOfRange && (
              <EditBtn
                  className={
                      isLoadingSelectDirEnvio === direccion.id
                          ? 'direccion-card__noneditable'
                          : ''
                  }
                  handleEdit={(e) => handleEditSchedule(e, direccion)}
              />
          )}
          {!isDirEnvio && isFromEcommerce && (
                <EditBtn handleEdit={handleEditDirFact} />
              )}
          {isLoadingSelectDirEnvio === direccion.id && !isOutOfRange && (
              <span className="direccion-card__spinner">
                  <Spinner className="spinner-dir-envio" />
              </span>
          )}
      </div>
  );
}

DireccionCard.propTypes = {
  direccion: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  setDirEnvio: PropTypes.func,
  setIsOpenForm: PropTypes.func,
  isDirEnvio: PropTypes.bool,
  isOutOfRange: PropTypes.bool
}

export default DireccionCard
