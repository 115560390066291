//VALIDATIONS FORMS
export const validarNIF = (identificacion) => {
    const regexNIF = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/;
    const regexNIE = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/;
    const regexCIF = /^[ABCDEFGHJNPQRSUVW][0-9]{7}[0-9A-J]$/;
    const regexNIPC = /^[125689]{1}\d{8}$/;
    const regexCIFInternacional = /^ES[ABCDEFGHJNPQRSUVW][0-9]{7}[0-9A-J]$/;
    const regexNIFCIFPortugal = /^[A-Z]{1,2}\d{9}$/;
    

    // Validar NIF o NIE en España
    if (regexNIF.test(identificacion) || regexNIE.test(identificacion)) {
        return true;
    }

    // Validar CIF en España
    if (regexCIF.test(identificacion) || regexCIFInternacional.test(identificacion.toUpperCase())) {
        return true;
    }

    // Validar NIPC en Portugal
    if (regexNIPC.test(identificacion)) {
        return true;
    }

    if (regexNIFCIFPortugal.test(identificacion)) {
        return true;
    }

    // Si no coincide con ninguno de los patrones, retornar falso
    return false;
};
  
  
export const handleValidPhone = (e) => {
    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
      e.preventDefault();
    }
    if (e.target.value.length >= 15 && e.key !== 'Backspace' && e.key !== 'Tab') {
        e.preventDefault();
      }
};

export const handleValidCp = (e) => {
    const { key, target } = e;

    if (!/[0-9\s-]/.test(key) && key !== 'Backspace' && key !== 'Tab') {
      e.preventDefault();
    }
  
    if (target.value.length >= 8 && key !== 'Backspace' && key !== 'Tab') {
      e.preventDefault();
    }
};

export const validatePostalCode = (code, country) => {
    let isValid;
    if(country === 'PT') {
      isValid = (/^\d{4}[\s-]?\d{3}$/).test(code) || (/^\d{4}$/).test(code)
    }
    if(country === 'ES'){
      isValid = (/^\d{5}$/).test(code)
    }
    return isValid;
};

