import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import './formDirEnvio.scss'
import { useForm } from 'react-hook-form'
import { useEffect, useRef, useState } from 'react'
import InputPaises from './InputPaises'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../shared/components/Spinner'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { checkIntracommunityNIF } from '../../../../../shared/services/checkout'
import { Alert } from '@mui/material'
import { isAddressOutOfRange } from '../../../../shared/helpers/functionalities'
import DeliverySchedule from './DeliverySchedule'
import GooglePlacesAutocomplete from '../shared/components/GooglePlacesAutocomplete'
import FormInput from '../../../../shared/components/FormInput'
import { handleValidCp, handleValidPhone, validarNIF, validatePostalCode } from '../../../../../utils'

const FormDirEnvio = ({ className = '', dirEnvio, addNewDirection, uploadDirection = ()=>{}, isDirFact = false, formEnvioRef, isOpenDirFacturacion, handleSelectDirFact }) => {
  const { t } = useTranslation()
  const checkboxRef = useRef(null)
  const [currentPaisId, setCurrentPaisId] = useState(1)
  const [b2b, setB2b] = useState(false)
  const [addressIssue, setAddressIssue] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showNifNoIntracomunitario, setShowNifNoIntracomunitario] = useState(false)
  const checkoutInfo = useSelector(state => {
    return state.checkout.entity
  });
  const isFromEcommerce = checkoutInfo?.presupuesto?.ecommerce;
  const { 
    register, 
    formState: { errors }, 
    handleSubmit, 
    setValue, 
    getValues,
    watch,
    reset, 
    setError,
    clearErrors } = useForm({
        mode: "onBlur",
        defaultValues: {
            id: '',
            nombre: '',
            telefono: '',
            direccion: '',
            codigo_postal: '',
            poblacion: '',
            area: '',
            pais_id: 1,
            horario_entrega_dia: '',
            horario_entrega_tarde: '',
        }
  });

  useEffect(() => {
    if (dirEnvio === 'otra') {
      reset({
        id: '',
        nombre: '',
        telefono: '',
        direccion: '',
        codigo_postal: '',
        poblacion: '',
        area: '',
        pais_id: 1,
        horario_entrega_dia: '',
        horario_entrega_tarde: '',
    });
      setCurrentPaisId(1)
    } else {
      reset(dirEnvio);
      setCurrentPaisId(dirEnvio?.pais_id || 1)
      
      !isDirFact && setValue('id', dirEnvio?.id)
      !isDirFact && setValue('horario_entrega_dia', dirEnvio?.horario_entrega_dia)
      !isDirFact && setValue('horario_entrega_tarde', dirEnvio?.horario_entrega_tarde)
      !isDirFact && setValue('pais_id', dirEnvio?.pais_id || 1)
      isDirFact && setValue('tipo_cliente_id', dirEnvio?.tipo_cliente_id)
      isDirFact && setValue('email', dirEnvio?.email)
      isDirFact && setValue('cif', dirEnvio?.cif)
      isDirFact && setValue('cif_ue', dirEnvio?.cif_ue)
      isDirFact && setValue('b2b', dirEnvio?.b2b)
      if(isDirFact && dirEnvio?.b2b) setB2b(true)
    }
  }, [dirEnvio])

  useEffect(() => {
    if(addressIssue) {
      toast.error(t(`errors.${addressIssue.issue}`))
    }
  }, [addressIssue])

  const onSaveData = async (data) => {
    if (!isDirFact && isOpenDirFacturacion) {
        handleSelectDirFact()
        return toast.info(t('carrito.envio.form.errors.dir-fact'))
    }
    if(isFromEcommerce) {
        if(isDirFact && !validarNIF(data.cif)) {
          return setError('cif', {type: 'valid', message: 'valid'})
        }
        if(!validatePostalCode(data.codigo_postal, (Number(data.pais_id) === 1 || Number(currentPaisId) === 1) ? 'ES' : 'PT')) {
          return setError('codigo_postal', {type: 'valid', message: 'valid'})
        }
    }
    if(!checkIfNameIsNotEmail(data.nombre)) return
    if (isLoading) return
    setIsLoading(true)
    // update invoicing address
    if (isDirFact) {
      data['direccion_facturacion'] = data['direccion'] + (data['piso'] ? ` ${data['piso']}` : '')
      delete data['direccion'] 
      data['codigo_postal_facturacion'] = data['codigo_postal']
      delete data['codigo_postal']
      data['poblacion_facturacion'] = data['poblacion']
      delete data['poblacion']
      data['area_facturacion'] = data['area']
      delete data['area']
      data['pais_facturacion_id'] = data['pais_id'] || currentPaisId
      delete data['pais_id']

      // check nif ue si no lo ha clicado lo pasamos a false para que no vuelva a salir
      if(data.pais_id !== 1 && data.cif_ue === null) {
        data.cif_ue = false
      }
      
      return uploadDirection(data)

    }
    
    if(data['piso']) {
      data['direccion'] = data['direccion'] + ` ${data['piso']}`
    }

     // update delivery schedule
     if (dirEnvio.id) {
      return uploadDirection(data)
    }
    
    // check different country
    if (!isFromEcommerce) {
      if(checkoutInfo?.presupuesto?.direccion_envio_id !== null  && checkoutInfo?.direcciones?.length > 0){
        const direccion = checkoutInfo?.direcciones?.find(dir => dir.id === checkoutInfo.presupuesto.direccion_envio_id)
        const { pais_id } = direccion
        if (pais_id !== data.pais_id) {
          setIsLoading(false)
          return setAddressIssue({status: true, issue: 'diferente'})}
      }
    }
    
    // check outside peninsula
    const areaLowCase = data.area.toLowerCase();
    if((Number(data.pais_id) !== 1 && Number(data.pais_id) !== 10) || 
        (isAddressOutOfRange(data.codigo_postal)) ||
        (areaLowCase.includes('ceuta') || areaLowCase.includes('melilla') || areaLowCase.includes('canarias') || areaLowCase.includes('madeira') || areaLowCase.includes('azores') || areaLowCase.includes('açores'))
      ) { 
      setIsLoading(false)
      return setAddressIssue({status: true, issue: 'fuera-rango'});
    }

    // create shipping address
    await addNewDirection({...data, entity_id: checkoutInfo.cliente.id})
    setIsLoading(false) 
  }
  
  const handleChangePais = (e) => {
    setCurrentPaisId(e.target.value)
    setValue('pais_id', e.target.value)
  }

  const handleIntracomunitario = async (e) => {
    if(!getValues('cif')) return
    if(!checkboxRef.current.checked) setShowNifNoIntracomunitario(false)
    setIsLoading(true)
    if(checkboxRef.current.checked) {
      const country = checkoutInfo.paises.find(country => country.id === Number(currentPaisId))
      const nif = getValues('cif')
      const res = await checkIntracommunityNIF({country: country.codigo, nif })
      if(res?.success) { 
        setValue('cif_ue', res.data.isValid)
        if(!res.data.isValid) {
          checkboxRef.current.checked = false
          setShowNifNoIntracomunitario(true)
        } else {
          toast.success(t('carrito.envio.form.info_cif_ue.ok'))
          setShowNifNoIntracomunitario(false)
        }
      } else {
        checkboxRef.current.checked = false
        toast.error(t('carrito.envio.form.info_cif_ue.error-request'))
      }
    }
    return setIsLoading(false)
  }

  const handleB2b = (e) => {
    setB2b(e.target.value)
    setValue('b2b', e.target.value === 'true')
  }

  const handleChangeDireccion = (direccion) => {
    Object.keys(direccion).forEach(function(key) {
        setValue(key, direccion[key])
        if (direccion[key]) clearErrors(key);
    });
  }

  const handleErrorDireccion = (nameId) => {
    const name = nameId.slice(4)
    if (!getValues(name)) setError('direccion', 'select-option')
  }

  const checkIfNameIsNotEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isEmail = regex.test(email.trim().toLowerCase())
    if(isEmail) {
        setError('nombre', {type: 'valid', message: 'valid'})
        return false;
    }
    return true;
  };

  return (
      <form
          ref={formEnvioRef}
          className={'form-dir-envio ' + className}
          onSubmit={handleSubmit(onSaveData)}
      >
          <h4>
              {isDirFact
                  ? t('carrito.envio.form.title-fact')
                  : t('carrito.envio.form.title')}
          </h4>
          {isDirFact && currentPaisId && currentPaisId !== 1 && (
              <>
                  <Alert severity="info">
                      {t('carrito.envio.form.info_cif_ue.info')}
                  </Alert>
                  <label className="form-dir-envio__intracomunitario">
                      <input
                          type="checkbox"
                          onChange={handleIntracomunitario}
                          ref={checkboxRef}
                          defaultChecked={dirEnvio?.cif_ue ? true : false}
                      />
                      {t('carrito.envio.form.cif_ue')}
                  </label>
                  {showNifNoIntracomunitario && (
                      <Alert severity="warning">
                          {t('carrito.envio.form.info_cif_ue.no-intra')}
                      </Alert>
                  )}
              </>
          )}
          <FormInput
              id="dir-name"
              text={t('carrito.envio.form.nombre') + ' *'}
              type="text"
              inputProps={{
                  ...register('nombre', {
                      required: true,
                      minLength: 4,
                      pattern: { value: /^[\p{L}]/u },
                  }),
              }}
              className={isDirFact ? (isFromEcommerce ? 'name-fact-ec' : 'name-fact') : 'name'}
              disabled={
                  (isDirFact || (dirEnvio !== 'otra' && checkoutInfo?.direcciones.length > 0)) &&
                  !isFromEcommerce &&
                  dirEnvio?.nombre
              }
              errorMessage={
                  errors.nombre 
                  && (errors?.nombre?.message === 'valid'
                    ? t('carrito.envio.form.errors.nombre.valid')
                    : t(`carrito.envio.form.errors.nombre.required`))
              }
          />
          {isDirFact && (
              <FormInput
                  id="dir-cif"
                  text={t('carrito.envio.form.cif') + ' *'}
                  type="text"
                  inputProps={{
                      ...register('cif', {
                          required: 'required',
                          pattern: { value: /^[0-9a-zA-Z]+$/ },
                          minLength: 9,
                      }),
                  }}
                  className={isFromEcommerce ? "cif-ec" : "cif"}
                  onBlur={(e) => {
                      if (currentPaisId !== 1 
                        && checkboxRef?.current?.checked) {
                            handleIntracomunitario(e);
                        } 
                  }}
                  disabled={
                      isDirFact &&
                      dirEnvio?.cif &&
                      !isFromEcommerce
                  }
                  errorMessage={
                      errors.cif &&
                      (errors?.cif?.message === 'valid'
                          ? t('carrito.envio.form.errors.cif.valid')
                          : t(`carrito.envio.form.errors.cif.required`))
                  }
              />
          )}
          {isDirFact &&
              isFromEcommerce && (
                  <div className="form-input b2b">
                      <select value={b2b} onChange={handleB2b}>
                          <option value={true}>
                              {t('carrito.envio.form.b2b')}
                          </option>
                          <option value={false}>
                              {t('carrito.envio.form.b2b-part')}
                          </option>
                      </select>
                      <label htmlFor="dir-pais">
                          {t('carrito.envio.form.b2b-title')}
                      </label>
                  </div>
              )}
          <FormInput
              id="dir-telefono"
              text={t('carrito.envio.form.telefono') + ' *'}
              type="tel"
              inputProps={{
                  ...register('telefono', {
                      required: true,
                      pattern: { value: /^\S.*[0-9]+$/ },
                      minLength: 9,
                  }),
              }}
              className="phone"
              onKeyDown={
                  isFromEcommerce && handleValidPhone
              }
              disabled={
                  (isDirFact || (dirEnvio !== 'otra' && checkoutInfo?.direcciones?.length > 0)) &&
                  !isFromEcommerce &&
                  dirEnvio?.telefono
              }
              errorMessage={
                  errors.telefono && t('carrito.envio.form.errors.telefono')
              }
          />
          {isDirFact && (
              <FormInput
                  id="dir-email"
                  text={t('carrito.envio.form.email') + ' *'}
                  type="email"
                  inputProps={{
                      ...register('email', {
                          required: 'requerido',
                          pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: 'formato',
                          },
                      }),
                  }}
                  className="email"
                  disabled={
                      isDirFact &&
                      dirEnvio?.email && !checkoutInfo?.presupuesto?.guest_ec
                  }
                  errorMessage={
                      errors.email &&
                      t(
                          `carrito.envio.form.errors.email.${errors?.email?.message}`
                      )
                  }
              />
          )}
          <div className="form-input pais">
              <InputPaises
                  id="dir-pais"
                  value={currentPaisId || 1}
                  onChange={handleChangePais}
                  paises={
                      isFromEcommerce
                          ? checkoutInfo.paises.filter(
                                (pais) => pais.id === 1 || pais.id === 10
                            )
                          : checkoutInfo.paises
                  }
                  disabled={(isDirFact || (dirEnvio !== 'otra' && checkoutInfo?.direcciones?.length > 0)) &&
                    !isFromEcommerce &&
                    dirEnvio?.pais_id}
              />
              <label htmlFor="dir-pais">{t('carrito.envio.form.pais')} *</label>
              {errors.pais_id && (
                  <span role="alert">
                      <FontAwesomeIcon icon={faCircleXmark} />{' '}
                      {t('carrito.envio.form.errors.pais')}
                  </span>
              )}
          </div>

          <GooglePlacesAutocomplete
              setError={setError}
              clearErrors={clearErrors}
              defaultValue={watch('direccion') || ''}
              watch={watch}
              errors={errors}
              disabled={(isDirFact || (dirEnvio !== 'otra' && checkoutInfo?.direcciones?.length > 0)) &&
                !isFromEcommerce &&
                dirEnvio?.direccion}
              country={
                  checkoutInfo.paises?.find(
                      (p) => p.id === Number(currentPaisId)
                  )?.codigo || 'ES'
              }
              onSelectResult={handleChangeDireccion}
          />
          <FormInput
              id="dir-piso"
              text={t('carrito.envio.form.piso')}
              className="piso"
              type="text"
              inputProps={{
                  ...register('piso', {
                      required: false,
                  }),
              }}
              disabled={
                  (isDirFact || (dirEnvio !== 'otra' && checkoutInfo?.direcciones?.length > 0)) &&
                  !isFromEcommerce &&
                  dirEnvio?.direccion
              }
          />
          <FormInput
              id="dir-codigo_postal"
              text={t('carrito.envio.form.codigo_postal') + ' *'}
              className="cp"
              type="text"
              inputProps={{
                  ...register('codigo_postal', {
                      required: true,
                      minLength: 4,
                  }),
              }}
              onKeyDown={isFromEcommerce && handleValidCp}
              onClick={handleErrorDireccion}
              disabled={
                  (isDirFact || (dirEnvio !== 'otra' && checkoutInfo?.direcciones?.length > 0)) &&
                  !isFromEcommerce &&
                  dirEnvio?.codigo_postal
              }
              errorMessage={
                  errors.codigo_postal &&
                  (errors?.codigo_postal?.message === 'valid'
                    ? t('carrito.envio.form.errors.codigo_postal.valid')
                    : t('carrito.envio.form.errors.codigo_postal.required')
                  )
              }
          />
          <FormInput
              id="dir-poblacion"
              text={t('carrito.envio.form.poblacion') + ' *'}
              type="text"
              inputProps={{
                  ...register('poblacion', {
                      required: true,
                      minLength: 3,
                      pattern: { value: /^[A-Za-z]/ },
                  }),
              }}
              className="poblacion"
              disabled={
                  (isDirFact || (dirEnvio !== 'otra' && checkoutInfo?.direcciones?.length > 0)) &&
                  !isFromEcommerce &&
                  dirEnvio?.poblacion
              }
              errorMessage={
                  errors.poblacion && t('carrito.envio.form.errors.poblacion')
              }
          />
          <FormInput
              id="dir-area"
              text={t('carrito.envio.form.area') + ' *'}
              type="text"
              inputProps={{
                  ...register('area', {
                      required: true,
                      minLength: 5,
                      pattern: { value: /^[A-Za-z]/ },
                  }),
              }}
              placeholder={t('carrito.envio.form.area-placeholder')}
              className="area"
              onClick={handleErrorDireccion}
              disabled={
                  (isDirFact || (dirEnvio !== 'otra' && checkoutInfo?.direcciones?.length > 0)) &&
                  !isFromEcommerce &&
                  dirEnvio?.area
              }
              errorMessage={errors.area && t('carrito.envio.form.errors.area')}
          />
          {!isDirFact && (
              <DeliverySchedule direccion={dirEnvio} setValue={setValue} />
          )}
          <div className="form-dir-envio__button">
              <button
                  disabled={isLoading}
                  className="form-dir-envio__button--btn"
                  type="submit"
              >
                  {isLoading ? (
                      <Spinner />
                  ) : (
                      <>{t('carrito.envio.form.guardar')}</>
                  )}
              </button>
          </div>
      </form>
  );
}

FormDirEnvio.propTypes = {
  className: PropTypes.string,
  dirEnvio: PropTypes.any,
  addNewDirection: PropTypes.func,
  uploadDirection: PropTypes.func,
  isDirFact: PropTypes.bool,
  formEnvioRef: PropTypes.any,
  isOpenDirFacturacion: PropTypes.bool,
  handleSelectDirFact: PropTypes.func
}
export default FormDirEnvio
