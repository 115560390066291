import { useEffect, useState } from 'react';
import imgDefault from '../../../../../../images/carrito/placeholder.png';

const Image = ({ img, className = '' }) => {
  const [foto, setFoto] = useState('')
  const [isImgDefault, setIsImgDefault] = useState(false)

  useEffect(() => {
    setFoto(img || imgDefault);
    if(!img) setIsImgDefault(true);
  }, [img])
  
  return (
    <img
      src={foto}
      onError={(e) => {
        setIsImgDefault(true)
        e.target.src = imgDefault
      }}
      className={`${className} ${isImgDefault ? 'img-default' : ''}`}
      alt='img'
    />
  )
}

export default Image

